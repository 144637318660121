<template>
  <label class="switch">
    <input
      type="checkbox"
      @change="$emit('input', $event.target.checked)"
      :checked="value"
      :disabled="disabled"
    />
    <span class="slider round"></span>
  </label>
</template>

<style lang="scss" scoped>
// The slider
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;

  &:before {
    position: absolute;
    content: "";
    height: 19.5px; //26
    width: 19.5px; //26
    left: 3px; //4
    bottom: 3px; //4
    background-color: white;
    transition: .4s;
  }

  &.round {
    border-radius: 25.5px;

    &:before {
      border-radius: 50%;
    }
  }
}

// The switch - the box around the slider
.switch {
  position: relative;
  display: inline-block;
  width: 45px; //60
  height: 25.5px; //34

  input[type=checkbox] {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: #2196F3;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }

    &:disabled + .slider {
      cursor: default;
      background-color: #e9e9e9;
      box-shadow: none;
    }

    &:checked:disabled + .slider {
      background-color: #c5d8ff;
    }

    &:checked + .slider:before {
      transform: translateX(19.5px); //26
    }
  }
}
</style>

<script>
export default {
  name: 'Checkbox',

  props: ['value', 'disabled'],
}
</script>